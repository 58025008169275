<template>
  <v-row justify="center">
    <v-dialog v-model="shower" persistent max-width="600px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template> -->
      <v-card>
        <v-card-title>
           <v-icon x-large color="#FF8C00">storage</v-icon>
          <span class="headline">Insert Customer</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" sm="6" md="12">
                <v-text-field
                  label="Name Customer"
                  placeholder=""
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
               <v-col cols="12" >
                <v-select
                  :items="['0-17', '18-29', '30-54', '54+']"
                  label="Package storage*"
                  required
                  outlined
                ></v-select>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field label="Stoerage" outlined  clearable required ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field label="Admin*" outlined clearable  ></v-text-field> 
              </v-col>
             
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                  label="Function"
                  multiple
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn   color="red" outlined text @click="$emit('closedialog')">Close</v-btn>
          <v-btn  color="blue darken-1" dark  @click="dialog = false">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    props : ["show"],
    data () {
      return {
        dialog: false,
      }
    },

   computed: {
    shower: {
      get() {
        if (this.show === true) {
          // this.loaddata();
          this.$emit('closeDrag')
        }
        return this.show;
      }
    }
  },
  }
</script>
